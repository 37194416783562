body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f3f4;
  /*color: white;*/
}


/* DarkMode */
body.dark-mode, .dark-mode #footer, .dark-mode #header, .dark-mode #top-bar, .dark-mode #dialog-title {
  background-color: #515860;
  color: white;
}
.dark-mode #dark-mode {
  background-color: #2a2f35;
  color: white;
}
.dark-mode #dark-mode-bg {
  background-color: #515860;
  color: white;
}
.dark-mode #dark-mode-font {
  color: white;
}

.dark-mode .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.dark-mode .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.dark-mode .gm-style .gm-style-iw-c,
.dark-mode .gm-style .gm-style-iw-t::after {
  background: #2a2f35;
}


/*.gm-style-iw-chr, .gm-style-iw.gm-style-iw-c, .gm-style-iw.gm-style-iw-d { */
/*.dark-mode #gm-style-iw-chr, .dark-mode #gm-style-iw.gm-style-iw-c, .dark-mode #gm-style-iw.gm-style-iw-d {*/
/*  background-color: green;*/
/*  color: white;*/
/*}*/
/*.dark-mode #dark-map {*/
/*  filter: invert(90%);*/
/*}*/




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* This adds extra bottom padding on mobile browsers */
.safe-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}