.App {
  text-align: center;
}

:root{
  --vh: 1vh;
}

.gm-style-iw-chr {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
}

.gm-style-iw.gm-style-iw-c {
  padding-top: 14px !important;
}


.map-container {
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
}

@media (max-width: 599px) {
  .map-container {
    height: calc(var(--vh, 1vh) * 100 - 56px); /*113*/
  }
}

@media (min-width: 600px) {
  .map-container {
    height: calc(var(--vh, 1vh) * 100 - 56px); /*121*/
  }
}